import classNames from "classnames"
import { connect } from "react-redux"
import { Link } from "gatsby"
import moment from "moment"
import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { Button } from "react-bootstrap"

import getLicenseFileName from "../../../../backend/src/licenses/license-file-name"
import { expectAuthenticationState } from "../../components/authentication"
import { downloadFile } from "../../utils/download"
import Loading from "../../components/loading"
import splitLicenses from "../../utils/split-licenses"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "./my-licenses.module.css"

const COLUMNS = [
  {
    dataField: "computerName",
    text: "Computer Name",
    formatter: function displayComputerName(computerName) {
      return (
        <span className="license_row" data-test="computerName">
          {computerName}
        </span>
      )
    },
  },
  {
    dataField: "computerId",
    text: "Machine ID",
    formatter: function displayComputerId(computerId) {
      return (
        <span className="license_row" data-test="computerId">
          {computerId}
        </span>
      )
    },
  },
  {
    dataField: "expirationDate",
    text: "Expiration Date",
    formatter: displayDateJSX,
  },
  {
    dataField: "licenseType",
    text: "Version",
    formatter: function displayKhiopsVersion(licenseType) {
      switch (licenseType) {
        case "v8_orange":
          return (
            <span className="license_row" data-test="licenseType">
              8.0
            </span>
          )
        case "v8_external":
          return (
            <span className="license_row" data-test="licenseType">
              8.0
            </span>
          )
        case "v9_orange":
          return (
            <span className="license_row" data-test="licenseType">
              9.0
            </span>
          )
        case "v9_external":
          return (
            <span className="license_row" data-test="licenseType">
              9.0
            </span>
          )
        default: {
          return (
            <span className="license_row" data-test="licenseType">
              10.0
            </span>
          )
        }
      }
    },
  },
  {
    dataField: "download",
    text: "Action",
    formatter: downloadLicenseJSX,
  },
]

const MyLicensesPage = () => (
  <Layout>
    <SEO title="My Licenses" />
    <h1 data-test="page-title">My Licenses</h1>
    <p><mark>From version 10.1 of Khiops, license files are no longer required.</mark> The licensing system of this site remains active for users of older versions.
      Note that Khiops is not unlicensed, having the same legal license agreement file as before.</p>
    <Button as={Link} to="/my-licenses/request" className="mb-3" data-testid="request-license">
      Request a license
    </Button>
    <ConnectedMyLicensesList />
  </Layout>
)

export default expectAuthenticationState({ connected: true, onboarded: true })(
  MyLicensesPage,
)

class MyLicensesList extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false,
      currentLicenses: [],
      previousLicenses: [],
      expiredLicenses: [],
    }
  }

  render() {
    const {
      currentLicenses,
      previousLicenses,
      expiredLicenses,
      loaded,
    } = this.state

    if (!loaded) {
      return (
        <>
          <h3>Please wait while we load your licenses</h3>
          <Loading />
        </>
      )
    }

    return (
      <>
        <BootstrapTable
          keyField="id"
          wrapperClasses="table-responsive"
          data={currentLicenses}
          columns={COLUMNS}
          noDataIndication="You do not have any licenses yet. Click on 'Request a license' to get one"
        />

        {previousLicenses.length > 0 && (
          <>
            <br />
            <h3 data-test="page-title">My previously requested licenses</h3>
            <p>
              The licenses listed below are only here for archiving purposes,
              please use the more recent licenses above as they expire later.
            </p>
            <BootstrapTable
              keyField="id"
              wrapperClasses="table-responsive"
              data={previousLicenses}
              columns={COLUMNS}
            />
          </>
        )}

        {expiredLicenses.length > 0 && (
          <>
            <br />
            <h3 data-test="page-title">My expired licenses</h3>
            <BootstrapTable
              keyField="id"
              wrapperClasses="table-responsive"
              data={expiredLicenses}
              columns={COLUMNS}
            />
          </>
        )}
      </>
    )
  }

  async componentDidMount() {
    const response = await fetch("/api/my-licenses", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
    if (response.ok) {
      const licenses = await response.json()
      const {
        currentLicenses,
        previousLicenses,
        expiredLicenses,
      } = splitLicenses(licenses, moment().format("YYYY-MM-DD"))
      this.setState({
        loaded: true,
        currentLicenses,
        previousLicenses,
        expiredLicenses,
      })
    } else {
      throw new Error(
        `Invalid response : ${response.status
        } content : ${await response.text()}`
      )
    }
  }
}

function downloadLicenseJSX(cell, row) {
  return (
    <div
      className="btn btn-primary"
      data-test="download-license"
      onClick={() => {
        downloadLicense(getLicenseFileName(row), row.licenseFile)
      }}
    >
      Download
    </div>
  )
}

function displayDateJSX(date) {
  const isExpired = moment(date).isBefore(moment())
  return (
    <div
      data-test="licenseExpirationDate"
      className={classNames(
        { ["expired_license"]: isExpired },
        "license_row"
      )}
    >
      {moment(date).format("DD/MM/YYYY")}
    </div>
  )
}

function downloadLicense(filename, licenseContent) {
  const blob = new window.Blob([licenseContent], {
    type: "application/octet-stream",
  })
  downloadFile(filename, blob)
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
  }
}

const ConnectedMyLicensesList = connect(mapStateToProps)(MyLicensesList)
